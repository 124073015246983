@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700;800&display=swap);

.navbarMenu {
  color: #fff;
  margin-right: 3rem;
  cursor: pointer;
}
.dashboardMain,
.container {
  transition: 0.3s all ease-in-out;
}
nav {
  position: fixed !important;
}

.navbarMain {
  background: #00985f;
  color: #ffffff !important;
  padding: 0.5rem 1.3rem !important;
  font-weight: 500;
  width: 100%;
  z-index: 1;
  justify-content: space-between !important;
}

.nav-position {
  position: initial !important;
}

.main .dashboardMain {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  margin-top: 1rem;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.adminImageWrapper {
  width: 50px;
  height: 50px;
}

.adminImage {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  margin-top: 8px;
}
.settingIcon {
  width: 20px !important;
  height: 20px;
  border-radius: 50%;
  margin-top: 5px;
}
.settingItem {
  font-size: 1.7rem;
  /* margin-bottom: 1rem; */
  transform: translateY(-3px);
}

.menu {
  margin-right: 4rem;
}

.main {
  display: flex;
}

/* SIDEBAR */
.main .sidebar {
  position: fixed;
  background-color: #444;
  width: 300px !important;
  flex: -1 4;
  height: 100%;
  animation: 0.5s sidebar_move forwards;
}
.main .sidebar::after {
  content: "";
  position: absolute;
  background-color: #444;
  width: 100%;
  height: 131px;
}

@keyframes sidebar_move {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.main .sidebar .sidebar_links {
  margin-top: 5rem;
}

.main .sidebar .sidebar_link {
  font-size: 0.9rem;
  width: 100%;
  padding: 0.7rem 2rem;
  cursor: pointer;
  color: #fff;
}

.main .sidebar .sidebar_link img {
  width: 20px;
  height: 20px;
}
.main .sidebar .sidebar_link span {
  margin-right: 1rem;
}
.main .sidebar .sidebar_link.active {
  background-color: #fff;
  font-size: 1rem;
  width: 100%;
  padding: 0.7rem 2rem;
  cursor: pointer;
  color: #444;
}

/* .main .dashboardMain {
  height: 101.5vh;
} */

.offers-wrapper {
  margin-top: 100px;
}

.main .dashboardMain .breadCrumb h4 {
  margin-right: 2rem;
  margin-top: 5rem;
}

.main .dashboardMain .breadCrumb .breadCrumbBadge {
  padding: 0.5rem 2rem;
  background-color: #444 !important;
  font-size: 0.8rem;
  cursor: pointer;
}

.main .mainCard {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 7rem !important;
  padding-bottom: 6rem;
  margin-top: 1rem;
  background-color: #f3f3f3;
  border: none;
  position: relative;
  height: 4vh;
  cursor: pointer;
}

.main .mainCard .notificationBadge {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-color: var(--primary-color);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
}
.main .mainCard .notificationBadge p {
  font-size: 1.1rem;
}

.main .mainCard img {
  width: 40px;
}

.main .mainCard .cardTitle {
  text-align: center;
  color: #444444;
}

.main .mainCard .cardText {
  text-align: center;
}

.left-margin {
  margin-left: 300px;
}

.nav-venueName {
  margin-top: 0.8rem;
  margin-left: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.notifications-container {
  position: relative;
  margin-right: 2rem;
  float: right;
}

.notifications-container svg {
  font-size: 25px;
}

.notifications-container span {
  position: absolute;
  top: -15px;
  right: -15px;
  color: #000;
}

/* RESPONSIVENESS */
@media (max-width: 1200px) {
  .navbarMain {
    z-index: 3;
  }
}
@media (max-width: 824px) {
  .main .sidebar {
    width: 37% !important;
    position: fixed;
  }
  .main .sidebar {
    z-index: 2;
  }
  .navbarMain {
    z-index: 3;
  }
  .main .dashboardMain {
    z-index: 0;
    margin: 0;
    padding: 0;
  }

  .left-margin {
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .main .sidebar {
    width: 80% !important;
  }
}

@media (max-width: 453px) {
  .dashboardMain input,
  .dashboardMain select {
    color: #898989;
    text-transform: capitalize;
    background-color: #f0f2f6;
    padding: 1rem 1rem;
    width: 100% !important;
    border: none;
  }
}

.navbarMenu {
  color: #fff;
  margin-right: 3rem;
  cursor: pointer;
}

.dashboardMain,
.container {
  transition: 0.3s all ease-in-out;
}

nav {
  position: fixed !important;
}

.navbarMain {
  background: #00985f;
  color: #ffffff !important;
  padding: 0.5rem 1.3rem !important;
  font-weight: 500;
  width: 100%;
  z-index: 1;
}

.nav-position {
  position: initial !important;
}

.main .dashboardMain {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  margin-top: 1rem;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.adminImageWrapper {
  width: 50px;
  height: 50px;
}

.adminImage {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  margin-top: 8px;
}

.settingIcon {
  width: 20px !important;
  height: 20px;
  border-radius: 50%;
  margin-top: 5px;
}

.settingItem {
  font-size: 1.7rem;
  /* margin-bottom: 1rem; */
  transform: translateY(-3px);
}

.menu {
  margin-right: 4rem;
}

.main {
  display: flex;
}

/* SIDEBAR */
.main .sidebar {
  position: fixed;
  background-color: #444;
  width: 300px !important;
  flex: -1 4;
  height: 100%;
  animation: 0.5s sidebar_move forwards;
}

.main .sidebar::after {
  content: "";
  position: absolute;
  background-color: #444;
  width: 100%;
  height: 131px;
}

.venue-sidebar .dropdown-toggle::after {
  display: none !important;
}

@keyframes sidebar_move {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

.main .sidebar .sidebar_links {
  margin-top: 5rem;
}

.main .sidebar .sidebar_link {
  font-size: 0.9rem;
  width: 100%;
  padding: 0.7rem 2rem;
  cursor: pointer;
  color: #fff;
}

.main .sidebar .sidebar_link img {
  width: 20px;
  height: 20px;
}

.main .sidebar .sidebar_link span {
  margin-right: 1rem;
}

.main .sidebar .sidebar_link.active {
  background-color: #fff;
  font-size: 1rem;
  width: 100%;
  padding: 0.7rem 2rem;
  cursor: pointer;
  color: #444;
}

/* 
.main .dashboardMain {
  height: 101.5vh;
} */

.offers-wrapper {
  margin-top: 100px;
}

.main .dashboardMain .breadCrumb h4 {
  margin-right: 2rem;
  margin-top: 5rem;
}

.main .dashboardMain .breadCrumb .breadCrumbBadge {
  padding: 0.5rem 2rem;
  background-color: #444 !important;
  font-size: 0.8rem;
  cursor: pointer;
}

.main .mainCard {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 7rem !important;
  padding-bottom: 6rem;
  margin-top: 1rem;
  background-color: #f3f3f3;
  border: none;
  position: relative;
  height: 4vh;
  cursor: pointer;
}

.main .mainCard .notificationBadge {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-color: var(--primary-color);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
}

.main .mainCard .notificationBadge p {
  font-size: 1.1rem;
}

.main .mainCard img {
  width: 40px;
}

.main .mainCard .cardTitle {
  text-align: center;
  color: #444444;
}

.main .mainCard .cardText {
  text-align: center;
}

.left-margin {
  margin-left: 300px;
}

.nav-venueName {
  margin-top: 0.8rem;
  margin-left: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.selectLanguage {
  background-color: #343434 !important;
  color: #fff !important;
  border: none !important;
}

/* RESPONSIVENESS */
@media (max-width: 1200px) {
  .navbarMain {
    z-index: 3 !important;
  }

  .nav-position {
    position: initial;
  }
}

@media (max-width: 824px) {
  .main .sidebar {
    width: 37% !important;
    position: fixed;
  }

  .main .sidebar {
    z-index: 2;
  }

  .navbarMain {
    z-index: 3 !important;

  }

  .main .dashboardMain {
    z-index: 0;
  }

  .left-margin {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .main .sidebar {
    width: 80% !important;
  }
}

@media (max-width: 453px) {

  .dashboardMain input,
  .dashboardMain select {
    color: #898989;
    text-transform: capitalize;
    background-color: #f0f2f6;
    padding: 1rem 1rem;
    width: 100% !important;
    border: none;
  }
}
.pointer {
    cursor: pointer;
}

.row {
    --bs-gutter-x: 1.5rem !important;
}

.home-container {
    scroll-behavior: smooth;
}

.hero_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.hero_content p {
    margin: 5px !important;
}

.hero_content h5 {
    margin: 5px !important;
}

.card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    height: 12rem;
    color: var(--bs-card-color);
}

.hero h1 {
    font-size: 3.3rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #3f3d56;
    margin-top: 1rem;
}

.hero h5 {
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 600;
    color: var(--primary-color);
    margin: 1rem auto;
}

.hero p {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 400;
    color: Gray;
    margin: 1rem auto;
}

.hero button {
    width: 88%;
    background-color: var(--primary-color);
    padding: 1rem 1rem;
    border: none;
}

.hero button:hover {
    width: 88%;
    background-color: var(--primary-color) !important;
    padding: 1rem 1rem;
}

.hero-section {
    max-width: 1400px;
    margin: 0 auto;
}

.hero .hero-img {
    text-align: center;
}

.hero .hero-img img {
    height: 400px;
    width: 100%;
    max-width: 400px;
}

.hero .hero-img .home-first-slide {
    height: 400px !important;
}

.hero .hero-img a {
    text-decoration: none;
    color: #3f3d56;
}

.hero .hero-img span {
    font-size: 1.3rem;
}


/* STEPS */

.steps {
    background-color: var(--primary-color);
    height: 100%;
    padding-bottom: 5rem;
    margin-top: 3rem;
}

.steps h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
}

.steps .card-title {
    font-weight: 700;
    text-transform: uppercase;
}

/* OFFERS */

.offers {
    margin-top: 7rem;
}

.offers h1 {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--primary-color);
    margin-top: 1rem;
}

.offers h5 {
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 500;
    color: #898989;
    margin: 1rem auto;
}

.offers p {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 400;
    color: rgb(0, 0, 0);
    margin: 1rem auto;
}

.offers button {
    width: 88%;
    background-color: var(--primary-color) !important;
    padding: 1rem 1rem;
    border: none;
}

.offers button:hover {
    width: 88%;
    background-color: var(--primary-color);
    padding: 1rem 1rem;
}

.offers .hero-img {
    text-align: center;
}

.offers .hero-img img {
    height: 510px;
}

.offers .hero-img a {
    text-decoration: none;
    color: #3f3d56;
}

/* footer */

.footer {
    background-color: var(--primary-color);
    height: 100%;
    margin-top: 4rem;
    display: flex;
    align-items: center;
}

.storeicons {
    display: flex;
    align-items: center;
}

.footer .footer-logo-1 {
    margin-top: -3rem;
    max-width: 300px;
    width: 100%;
    text-align: center;
    height: 90px;
}

.footer .footer-logo-2-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.footer .footer-logo-2-wrapper .f-logo2 {
    width: 150px;
    height: 100px;
}

.footer .footer-logo-2-wrapper .f-logo3 {
    width: 200px;
    height: 90px;
    margin: 0.5rem 0 0 1rem;
}

.footer .footerLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.footer .footerLinks button {
    font-size: 1rem;
}

.footer .footerLinks a {
    max-width: 100px;
    color: #fff;
    margin-left: 13px;
    font-size: 1.3rem;
    text-decoration: none;
}

.footerLinks .btn-account {
    margin-left: 16px;
}

.footer .contact-info {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
}

.footer .contact-info a {
    text-decoration: none;
    color: #000;
    width: 120px;
    text-align: center;
    padding: 0.4rem 0;
    background-color: #fff;
    border-radius: 0.3rem;
    font-weight: 700;
    margin-left: 2.2rem;
}

.footer .contact-info .icon {
    color: #fff;
    margin-left: 2rem;
    font-size: 1.5rem;
    margin-top: 0.7rem;
    text-decoration: none;
}

.footer .contact-info button {
    color: rgb(28, 28, 28);
    margin-left: 2rem;
    font-size: 0.8rem;
    text-decoration: none;
    margin-top: 2rem;
    border: none;
    padding: 0.4rem 2.3rem;
}

.footer .storeicons-wrapper,
.storeicons .storeicons-wrapper {
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    margin-bottom: 40px;
}

.footer .storeicons-wrapper img,
.storeicons .storeicons-wrapper img {
    width: 180px;
    height: 60px;
    border-radius: 5px;
    margin-top: 20px;
}

.list-item {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    font-size: 1rem;
}

.faq-container .sec-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.faq-container .row {
    display: flex;
    justify-content: center;
}

.faq-container input {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}

.faq-container ul {
    list-style: none;
    text-decoration: none;
}

.faq-container ul li {
    padding: 1rem 0;
}

.faq-container ul p {
    color: grey;
}

.faq-container .list-group-item1:not(:last-child) {
    border-bottom: 1px solid #d6d6d6 !important;
}

.faq-container .list-group-item1 svg {
    font-size: 1.7rem;
}

.faq-container .list-group-item1 span {
    font-weight: bold;
    font-size: 1.1rem;
}

.venue-wrapper .single-item {
    position: relative;
}

.venue-wrapper .single-item img {
    max-height: 270px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    position: relative;
}

.venue-wrapper .single-item .btn-wrapper,
.venue-wrapper .single-item:hover .btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 10px;
}

.venue-wrapper .single-item button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}

.single-venue-wrapper {
    margin-top: 50px;
}

.single-venue-wrapper svg,
.venue-list svg {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
}

.details-wrapper {
    border: 1px solid #dadada;
    border-radius: 5px;
    max-height: 320px;
}

.details-wrapper h3 {
    font-weight: bold;
    padding: 1rem 0;
}

.details-wrapper hr {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 0;
}

.details-wrapper .league-wrapper {
    display: flex;
    justify-content: space-between;
}

.details-wrapper .league-wrapper p {
    font-size: 1.1rem;
    font-weight: 500;
    color: #898989;
}

.details-wrapper .team-wrapper {
    display: flex;
    padding: 0.5rem 0;
}

.details-wrapper .team-wrapper img {
    width: 40px;
    margin-right: 1rem;
}

.details-wrapper .team-wrapper p {
    font-size: 1.1rem;
    font-weight: 600;
}

.img-wrapper {
    border: 1px solid #e2e2e2;
    border-radius: 1rem;
    margin: 0 1rem;
    padding: 1rem;
}

.facilities-wrapper .Custom_container {
    margin: 0 !important;
    padding: 2rem !important;
}

.facilities-wrapper .feature-previeW-container {
    height: 4rem !important;
}

.working-hours-wrapper h6 {
    font-weight: 800;
    text-transform: capitalize;
}

.working-hours-wrapper .time-wrapper {
    display: flex;
    justify-content: space-between;
}

.home-container hr {
    height: 3px;
    background-color: #00985f;
    opacity: 1;
    border: none;
}

.venues-wrapper {
    margin: 20px auto 100px;
}

.venues-wrapper .venues-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.venues-wrapper .venues-header h1 {
    font-weight: bolder;
}

.venu-home-breaker {
    height: 60px !important;
}

.venue-list-item {
    margin-bottom: 25px;
}

.venue-list-item img {
    width: 150px;
    height: 150px;
    border-radius: 5px;
}

.venue-list-item .venue-list-item-inner-container {
    display: flex;
    justify-content: space-between;
}

.venue-list-item div {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.story-container {
    background-image: url("/assets/grass.jpg");
    height: 300px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.story-container p {
    max-width: 350px;
    font-size: 18px;
    text-align: center;
}

.empty-container {
    min-height: 100px;
    background-size: cover;
    background: inherit !important;
}

.fixture-client-container {
    text-align: center;
    width: 100% !important;
}

.fixture-client-container h1 {
    font-weight: bolder;
}

.fixture-calender-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    grid-gap: 50px;
    gap: 50px;
}

.fixture-calender-wrapper svg {
    font-size: 40px;
    margin-top: 50px;
}

.awssld__content {
    background-color: unset !important;
}

.awssld__container {
    padding-bottom: 40rem !important;
}

.restaurentCardHome {
    height: 400px !important;
}

.restaurentCardHome img {
    height: 300px !important;
}

.singleVenueItem {
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #bfc0bf;
    margin-bottom: 10px;
    margin-right: 10px !important;
}

.singleVenueItem img {
    width: 100px !important;
    height: 80px !important;
}

.third-slide-img {
    width: 100%;
    height: 600px;
}

@media screen and (max-width: 700px) {
    .hero_content {
        margin: 20px 0;
    }

    .hero_content h1 {
        font-size: 2.3rem;
    }

    .hero_content .second-slide-header {
        font-size: 3rem !important;
    }

    .hero_content .hero-reg-btn {
        margin-bottom: 80px !important;
    }

    .third-slide-img {
        height: 600px;
    }
}

/* Landing page */


.fs-40 {
    font-size: 40px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.main-app-wrapper {
    max-width: 1500px;
    margin: 0 auto;
    font-family: "Barlow Condensed", sans-serif;
}

.btn-primary {
    background-color: var(--primaryColor) !important;
    border: 1px solid var(--primaryColor);
}

.btn-primary:hover {
    background-color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
}

.container-fluid {
    padding-inline: 4rem !important;
}

.header {
    position: fixed;
    background: var(--primaryColor);
    color: #fff;
    height: 90px;
    width: 100%;
    z-index: 3;
}

.header .nav-link {
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

.header a.dropdown-item {
    color: #777;
}

.header .navbar-nav {
    color: #fff;
    margin-right: 7rem;
}

.header .linkBtn {
    padding: 0.5rem 2rem !important;
}

.header nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100%;
    max-height: 90px;
}

/* hero */

.landingHero {
    background-image: linear-gradient(to bottom, #05050596, #0505053b),
        url("/assets/LandingImages/hero.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 180px 0;
}

.landingHero .heroContent {
    color: #fff;
    margin-top: 1rem;
}

.landingHero .sub-heading {
    font-size: 20px;
}

.landingHero .heroContent h1 {
    color: #fff;
    font-size: 40px;
    line-height: 4rem;
}

.heroContent p {
    color: #fff;
}

.landingHero .btnCustom,
.landingHero .btnCustom:hover {
    padding: 1rem 5rem;
    margin-top: 1rem;
    background-color: var(--primaryColor) !important;
    color: white;
    font-size: 20px;
}

.landingHero .heroContent .stores {
    margin-top: 110px;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    grid-gap: 50px;
    gap: 50px;
}

.landingHero .heroContent .stores .storeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 1rem;
    cursor: pointer;
}

.landingHero .heroContent .stores .storeBtn img.playstore-icon {
    width: 300px !important;
    height: 122px !important;
}

.landingHero .heroContent .stores .storeBtn img {
    width: 250px;
    height: 83px;
    transform: translateY(-0.5rem);
}

.landingHero .heroContent .stores .btnContent p {
    font-size: 1.4rem;
}

.landingHero .heroContent .stores .btnContent span {
    font-size: 0.8rem;
}

/* venue */

.venue {
    margin-top: 7rem;
}

.venue .venueContent {
    margin-top: 8rem;
}

.venue .venueContent h1 {
    font-size: 2rem;
    font-weight: 500;
    color: #4d4d4d;
}

.venue .venueContent p {
    font-size: 20px;
    font-weight: 400;
    color: #4d4d4d;
}

.venue .venueContent .btnCustom,
.venue .venueContent .btnCustom:hover {
    padding: 1rem 5rem;
    margin-top: 1rem;
    background-color: var(--primaryColor) !important;
    color: #fff;
    font-size: 20px;
}

/* about */

.about {
    height: 100%;
    padding-block: 4rem;
    color: #fff;
    background-image: url("/assets/LandingImages/aboutBG.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 5rem !important;
}

.about .transparentLogo {
    position: relative;
}

.about .transparentLogo .transparentLogoImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%, -50%);
}

.about h5 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
}

.about h1 {
    font-size: 3rem;
    font-weight: 500;
}

/* about2 */

.about2 {
    background-image: url("/assets/LandingImages/about2BG.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    padding-block: 5rem;
    color: #fff;
}

.about2 h5 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
}

.about2 h1 {
    font-size: 3rem;
    font-weight: 500;
}

.about2 .about2Card {
    margin-top: 2rem;
}

.about2 .about2Card h3 {
    color: var(--primaryColor);
    font-size: 2.5rem;
    font-weight: 700;
}

/* features */

.features {
    margin-top: 3rem;
}

.features h1 {
    text-align: center;
    font-weight: 500;
    color: #333333;
    position: relative;
}


.features .featureContent {
    margin-top: 7rem;
}

.features .featureContent h2 {
    margin-bottom: 2rem;
    font-weight: 700;
    color: #333333;
    position: relative;
    display: inline-block;
}


.features .featureContent p {
    font-size: 1.2em;
    color: #4d4d4d;
}

.features .featureContent button.btnCustom,
.features .featureContent button.btnCustom:hover {
    padding: 1rem 5rem;
    margin-top: 1rem;
    background-color: var(--primaryColor) !important;
    color: #fff;
}

/* offer */

.offer {
    background-color: var(--primaryColor);
    height: 100%;
    padding-top: 2rem;
    margin-top: 4rem;
    color: #fff;
    padding-bottom: 4rem;
}

.offer h1 {
    text-align: center;
    font-weight: 500;
    color: #fff;
    position: relative;
}


.offer .btnCustom,
.offer .btnCustom:hover {
    padding: 17px 6rem;
    background-color: #444444;
    width: 100%;
}

.offer .offerContent {
    margin-top: 50px;
}

.offer .offerContent h5 {
    margin-bottom: 30px;
    font-size: 2.7rem;
}

.offer .offerContent p {
    margin-bottom: 30px;
    font-size: 1.1rem;
    line-height: 2rem;
}

/* contact */

.contact {
    margin-top: 2rem;
}

.contact .inputs {
    margin-block: 2rem;
}

.contact .inputs .input {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-top: 2rem;
}

.contact .inputs .input span {
    font-size: 20px;
}

.contact .inputs .input input {
    padding: 1rem;
    outline: none;
    background-color: #f0f2f6;
    border: none;
    font-size: 20px;
}

.contact .inputs .input textarea {
    padding: 1rem;
    outline: none;
    background-color: #f0f2f6;
    border: none;
    font-size: 20px;
}

.contact .inputs .input button {
    background-color: var(--primaryColor);
    color: #fff;
    padding: 0.8rem;
}

/* footer */

.footer {
    background-color: #444444;
    padding-block: 2rem;
}

.footer .stores {
    margin-top: 70px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 50px;
    gap: 50px;
}

.footer .stores .storeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 5rem 0.2rem 2rem;
    background-color: #333333;
    color: #fff;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 1rem;
    border-radius: 10px;
    cursor: pointer;
}

.footer .stores .storeBtn img {
    transform: translateY(-0.5rem);
}

.footer .stores .btnContent p {
    font-size: 1.4rem;
}

.footer .stores .btnContent span {
    font-size: 0.8rem;
}

.footer .socialIcons {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.footer .socialIcons i {
    color: #fff;
    font-size: 2.1rem;
}

.footer button {
    background-color: var(--primaryColor);
    padding: 1rem 5rem;
    color: #fff;
}

.navbar-toggler:not(:disabled):not(.disabled) {
    height: 40px;
    width: 43px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    position: relative;
}

.landing-container .navbar-toggler-icon {
    background-image: none;
}

.navbar-toggler .navbar-toggler-icon {
    width: 1em;
    height: 1em;
}

.toggleImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.venueButton {
    background-color: #343434;
    border-radius: 4px;
    margin-left: 10px;
}

.footer-left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}

.footer-left .footer-logo {
    width: 150px;
    margin-bottom: 20px;
}

.footer-left p {
    font-size: 18px;
}

.footer-left h6 {
    margin-bottom: 20px;
}

.footer-left .stores-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.footer-left .stores-btns .store_btn img {
    width: 30px !important;
}

.footer-left .stores-btns .store_btn p {
    margin: 0;
}

.footer-left .stores-btns .store_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    grid-gap: 10px;
    gap: 10px;
}

.footer-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.footer-right img {
    width: 300px;
}

.social-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 25px;
    gap: 25px;
    margin-top: 20px;
}

.social-icons .icon {
    font-size: 30px;
}

/* RESPONSIVNESS */

/* 725 */

@media (max-width: 725px) {
    .landingHero .heroContent .stores {
        margin-top: 110px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 50px;
        gap: 50px;
    }

    .landingHero .heroImg {
        margin-top: 4rem;
    }

    .offer .btnCustom,
    .offer .btnCustom:hover {
        padding: 17px;
        background-color: #444444;
        width: 100%;
    }

    .offer {
        padding-bottom: 4rem;
    }

    .features .featureContent button.btnCustom,
    .features .featureContent button.btnCustom:hover {
        padding: 1rem 7rem;
        margin-block: 4rem;
    }

    .header nav {
        width: 70%;
    }

    .venueButton {
        margin-left: 0;
        margin-top: 20px
    }
}
:root {
  --green: #00b678;
  --purple: #00bbdd;
  --white: #fff;
  --primaryColor: #27ae60;
  --primary-color: #00985f;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 62.5%;
  /* zoom: 0.9; */
  font-family: "Barlow Condensed", sans-serif !important;
}


.primary-bg-color {
  background-color: var(--green) !important;
  color: var(--white) !important;
}

.alignRightWebsite {
  text-align: end !important;
}

.alignRight {
  text-align: start !important;
}

.text-center {
  text-align: center !important;
}

.color-primary {
  color: var(--green);
}

.full-width {
  width: 100%;
}

.text-nowrap {
  text-wrap: nowrap;
}

a {
  font-size: 0.9rem;
}

p {
  font-size: 0.9rem;
}

h5 {
  font-size: 1.1rem;
}

.spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-17 {
  font-size: 17px !important;
  font-weight: bold !important;
}

.f-bold {
  font-weight: bold !important;
}

.fs-15 {
  font-size: 15px !important;
}

.form-control {
  height: auto !important;
}

.wrapper {
  margin: 3rem 0 0 8em;
}

.alight-pc-left {
  display: flex;
  flex-direction: row;
}

.alight-pc-right {
  display: flex;
  flex-direction: row;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  display: flex;
  justify-content: space-around;
  color: #444444 !important;
}

.nav-tabs .nav-link {
  color: #444444 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border: none;
  border-bottom: 4px solid var(--primary-color) !important;
  color: #444444 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.secondary-container {
  margin: 4rem 0 2rem 0;
}

.empty-link {
  display: inherit !important;
  padding: 1px !important;
}

.dash__card {
  box-shadow: 0 0 20px #b9b9b9;
  padding: 30px 50px;
  margin: 10px 0px;
  border-radius: 8px;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash__card .dash__card_icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash__card .dash__card_icon svg {
  fill: white;
  width: 40px;
  height: 40px;
}

.dash__card p {
  margin: 10px 0;
  font-size: 1.6rem;
}

.border-purple {
  border-left: 5px solid var(--purple);
}

.border-green {
  border-left: 5px solid var(--green);
}

.bg-green {
  color: var(--white);
  background-color: var(--green);
}

.bg-purple {
  color: var(--white);
  background-color: var(--purple);
}

.text-purple {
  color: var(--purple);
}

.text-green {
  color: var(--green);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.deleteAccount {
  color: red;
  float: right;
  cursor: pointer;
}

.modal-title {
  text-align: center;
  font-size: 1.5rem;
}

.changePassword {
  color: #00985f;
  float: right;
  cursor: pointer;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.modal-body {
  padding-bottom: 4rem;
}

.modal-body input {
  padding: 1rem;
  width: 100%;
  background-color: #f0f2f6;
  border: none;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.member_custom_table {
  display: flex !important;
  justify-content: space-between;
  padding: 2rem 3rem 0.5rem 3rem;
  border-bottom: 1px solid #eee;
}

.member_custom_table .custom_badge {
  color: var(--green);
}

.dashboardMain input,
.dashboardMain select {
  color: #898989;
  background-color: #f0f2f6;
  padding: 1rem 3rem;
  width: 100%;
  height: auto !important;
  border: none;
  font-size: 1rem;
}

.btn-custom {
  background-color: var(--green) !important;
  width: 100%;
  padding: 1rem 2rem !important;
  color: #fff !important;
}

.previewCard {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.previewCard .proviewMainCard {
  background-color: var(--green) !important;
  width: 60% !important;
  height: 100%;
  padding: 1rem;
}

.exclamationIcon {
  float: right;
  font-size: 1.2rem;
}

.previewCard .proviewMainCard h5 {
  text-align: center;
  font-size: 2rem;
}

.btn-offer {
  background-color: #fff !important;
  color: var(--green) !important;
}

#react-select-2-listbox {
  width: 100%;
}

.offerDate {
  display: inherit !important;
}

@media screen and (max-width: 900px) {
  .hero .hero-img {
    text-align: start;
  }
}

.eventFileInput input {
  margin-top: -2rem !important;
}

.selectLanguage {
  padding: 5px !important;
}

.awssld__timer {
  opacity: 0 !important;
}

.matches-datepicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;
}

.matches-datepicker input {
  width: 400px !important;
}

.matches-datepicker svg {
  font-size: 30px;
}


.fixtureBottom {
  margin-top: 3rem;
}

.fixtureBottom .leagueMatch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.fixtureBottom .leagueMatch img {
  width: 30px;
}

.fixtureBottom .leagueMatch h5 {
  font-weight: 700;
}

.fixtureBottom .leagueMatch p {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  width: 100px;
  flex-wrap: wrap;
  transform: translateY(5px);
}

.fixtureBottom .leagueMatch button {
  background-color: var(--primary-color);
  padding: 0.2rem 2rem;
  color: #fff;
}

.fixtureBottom .leagueMatch button:hover {
  background-color: var(--primary-color);
  padding: 0.2rem 2rem;
  color: #fff;
}

.offerWrapper .wrapper {
  margin: 0 !important;
}

.navSecondVariant .navbar-toggler-icon {
  background-image: none !important;
}

.disabled-text {
  color: #dedbdb !important;
}

.map-marker-container svg {
  font-size: 25px;
  fill: #be5252;
}


.navbarMain .dropdown.venue {
  max-width: inherit !important;
  width: auto !important;
  margin-top: 15px !important;
}

.navbarMain .dropdown .dropdown-item {
  white-space: pre-wrap !important;
}

.form-switch .form-check-input {
  position: relative !important;
  width: 40px !important;
  height: 20px !important;
}

.form-switch input,
.form-check input {
  padding: 0.5rem 1.2rem !important;
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.nav-link {
  color: #fff !important;
  font-size: 1rem;
}

.nav-link:hover {
  color: #fff;
}

.navbar-expand-lg .navbar-collapse {
  /* flex-basis: auto; */
  justify-content: flex-end;
  align-items: center;
}

.navbar-expand-lg .navbar-collapse button,
.navbar-expand-lg .navbar-collapse button:hover {
  font-size: 0.9rem;
  background-color: #444444;
  transform: translateY(5px);
  border: none;
}


.fileInput {
  position: absolute;
  top: 50%;
  left: 5%;
  opacity: 0;
}

.formFileInput {
  position: relative;
  width: 40%;
}

.formInputs .formInput {
  margin-top: 2rem;
}

.formInput input {
  color: #898989;
  background-color: #f0f2f6;
  padding: 1rem 3rem;
  width: 90%;
  border: none;
  margin: 0 !important;
}

.btnFile {
  background-color: #f0f2f6 !important;
  padding: 1.3rem;
  border: none;
  font-size: 0.9rem;
  width: 100%;
}

.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.forgot-password-container .login-form {
  max-width: 600px;
}

.Custom_container {
  background-color: var(--primary-color);
  padding: 5rem;
  margin-top: 2rem !important;
  border-radius: 8px;
}

.Custom_container .col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Custom_container p {
  color: #fff;
  font-size: 0.7rem;
  margin-top: 1rem;
}

.feature-previeW-container {
  border: 1px solid #fff;
  padding: 0rem 1.5rem;
  color: #fff;
  height: 60px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.feature-previeW-container .cross-line {
  position: absolute;
  height: 2px;
  background: #fff;
  width: 120%;
  transform: rotate(147deg);
  transition: width 2s;
  -webkit-transition: width 2s;
  -moz-transition: width 2s;
  display: block;
}

.feature-previeW-container svg {
  font-size: 2rem;
}

.feature-previeW-container img {
  width: 36px;
}


.profileMain .secondryHeading {
  font-weight: unset !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileMain_portal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 10px;
}

@media (max-width: 824px) {
  .main .dashboardMain {
    z-index: 0;
    margin: 0;
  }

  .profile_main .navLinks .tab-content {
    padding: 10px;
  }

  .portal-profile-page-table .day {
    position: absolute;
    top: 25px;
    left: 1rem;
  }

  .navbarMain .dropdown {
    max-width: 100% !important;
    width: auto !important;
  }

  .form-switch {
    padding-left: 0 !important;
    width: 38px;
  }
}

@media (max-width: 770px) {
  .profile_main .customTable th {
    font-size: 1rem !important;
  }

  .breadCrumb {
    margin-top: 50px;
  }

  .w-100 {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .profile_main .navLinks .tab-content .formInput input {
    width: 95% !important;
  }

  .portal-profile-page-table .day {
    position: absolute;
    top: 0;
    left: 15px;
  }

  .leagueMatches .btn-fixture {
    width: 36%;
  }

  .profile_main .customTable th {
    font-size: 12px !important;
  }
}
