@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700;800&display=swap");

:root {
  --green: #00b678;
  --purple: #00bbdd;
  --white: #fff;
  --primaryColor: #27ae60;
  --primary-color: #00985f;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 62.5%;
  /* zoom: 0.9; */
  font-family: "Barlow Condensed", sans-serif !important;
}


.primary-bg-color {
  background-color: var(--green) !important;
  color: var(--white) !important;
}

.alignRightWebsite {
  text-align: end !important;
}

.alignRight {
  text-align: start !important;
}

.text-center {
  text-align: center !important;
}

.color-primary {
  color: var(--green);
}

.full-width {
  width: 100%;
}

.text-nowrap {
  text-wrap: nowrap;
}

a {
  font-size: 0.9rem;
}

p {
  font-size: 0.9rem;
}

h5 {
  font-size: 1.1rem;
}

.spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-17 {
  font-size: 17px !important;
  font-weight: bold !important;
}

.f-bold {
  font-weight: bold !important;
}

.fs-15 {
  font-size: 15px !important;
}

.form-control {
  height: auto !important;
}

.wrapper {
  margin: 3rem 0 0 8em;
}

.alight-pc-left {
  display: flex;
  flex-direction: row;
}

.alight-pc-right {
  display: flex;
  flex-direction: row;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  display: flex;
  justify-content: space-around;
  color: #444444 !important;
}

.nav-tabs .nav-link {
  color: #444444 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border: none;
  border-bottom: 4px solid var(--primary-color) !important;
  color: #444444 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.secondary-container {
  margin: 4rem 0 2rem 0;
}

.empty-link {
  display: inherit !important;
  padding: 1px !important;
}

.dash__card {
  box-shadow: 0 0 20px #b9b9b9;
  padding: 30px 50px;
  margin: 10px 0px;
  border-radius: 8px;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash__card .dash__card_icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash__card .dash__card_icon svg {
  fill: white;
  width: 40px;
  height: 40px;
}

.dash__card p {
  margin: 10px 0;
  font-size: 1.6rem;
}

.border-purple {
  border-left: 5px solid var(--purple);
}

.border-green {
  border-left: 5px solid var(--green);
}

.bg-green {
  color: var(--white);
  background-color: var(--green);
}

.bg-purple {
  color: var(--white);
  background-color: var(--purple);
}

.text-purple {
  color: var(--purple);
}

.text-green {
  color: var(--green);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.deleteAccount {
  color: red;
  float: right;
  cursor: pointer;
}

.modal-title {
  text-align: center;
  font-size: 1.5rem;
}

.changePassword {
  color: #00985f;
  float: right;
  cursor: pointer;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.modal-body {
  padding-bottom: 4rem;
}

.modal-body input {
  padding: 1rem;
  width: 100%;
  background-color: #f0f2f6;
  border: none;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.member_custom_table {
  display: flex !important;
  justify-content: space-between;
  padding: 2rem 3rem 0.5rem 3rem;
  border-bottom: 1px solid #eee;
}

.member_custom_table .custom_badge {
  color: var(--green);
}

.dashboardMain input,
.dashboardMain select {
  color: #898989;
  background-color: #f0f2f6;
  padding: 1rem 3rem;
  width: 100%;
  height: auto !important;
  border: none;
  font-size: 1rem;
}

.btn-custom {
  background-color: var(--green) !important;
  width: 100%;
  padding: 1rem 2rem !important;
  color: #fff !important;
}

.previewCard {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.previewCard .proviewMainCard {
  background-color: var(--green) !important;
  width: 60% !important;
  height: 100%;
  padding: 1rem;
}

.exclamationIcon {
  float: right;
  font-size: 1.2rem;
}

.previewCard .proviewMainCard h5 {
  text-align: center;
  font-size: 2rem;
}

.btn-offer {
  background-color: #fff !important;
  color: var(--green) !important;
}

#react-select-2-listbox {
  width: 100%;
}

.offerDate {
  display: inherit !important;
}

@media screen and (max-width: 900px) {
  .hero .hero-img {
    text-align: start;
  }
}

.eventFileInput input {
  margin-top: -2rem !important;
}

.selectLanguage {
  padding: 5px !important;
}

.awssld__timer {
  opacity: 0 !important;
}

.matches-datepicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;
}

.matches-datepicker input {
  width: 400px !important;
}

.matches-datepicker svg {
  font-size: 30px;
}


.fixtureBottom {
  margin-top: 3rem;
}

.fixtureBottom .leagueMatch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.fixtureBottom .leagueMatch img {
  width: 30px;
}

.fixtureBottom .leagueMatch h5 {
  font-weight: 700;
}

.fixtureBottom .leagueMatch p {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  width: 100px;
  flex-wrap: wrap;
  transform: translateY(5px);
}

.fixtureBottom .leagueMatch button {
  background-color: var(--primary-color);
  padding: 0.2rem 2rem;
  color: #fff;
}

.fixtureBottom .leagueMatch button:hover {
  background-color: var(--primary-color);
  padding: 0.2rem 2rem;
  color: #fff;
}

.offerWrapper .wrapper {
  margin: 0 !important;
}

.navSecondVariant .navbar-toggler-icon {
  background-image: none !important;
}

.disabled-text {
  color: #dedbdb !important;
}

.map-marker-container svg {
  font-size: 25px;
  fill: #be5252;
}


.navbarMain .dropdown.venue {
  max-width: inherit !important;
  width: auto !important;
  margin-top: 15px !important;
}

.navbarMain .dropdown .dropdown-item {
  white-space: pre-wrap !important;
}

.form-switch .form-check-input {
  position: relative !important;
  width: 40px !important;
  height: 20px !important;
}

.form-switch input,
.form-check input {
  padding: 0.5rem 1.2rem !important;
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.nav-link {
  color: #fff !important;
  font-size: 1rem;
}

.nav-link:hover {
  color: #fff;
}

.navbar-expand-lg .navbar-collapse {
  /* flex-basis: auto; */
  justify-content: flex-end;
  align-items: center;
}

.navbar-expand-lg .navbar-collapse button,
.navbar-expand-lg .navbar-collapse button:hover {
  font-size: 0.9rem;
  background-color: #444444;
  transform: translateY(5px);
  border: none;
}


.fileInput {
  position: absolute;
  top: 50%;
  left: 5%;
  opacity: 0;
}

.formFileInput {
  position: relative;
  width: 40%;
}

.formInputs .formInput {
  margin-top: 2rem;
}

.formInput input {
  color: #898989;
  background-color: #f0f2f6;
  padding: 1rem 3rem;
  width: 90%;
  border: none;
  margin: 0 !important;
}

.btnFile {
  background-color: #f0f2f6 !important;
  padding: 1.3rem;
  border: none;
  font-size: 0.9rem;
  width: 100%;
}

.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.forgot-password-container .login-form {
  max-width: 600px;
}

.Custom_container {
  background-color: var(--primary-color);
  padding: 5rem;
  margin-top: 2rem !important;
  border-radius: 8px;
}

.Custom_container .col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Custom_container p {
  color: #fff;
  font-size: 0.7rem;
  margin-top: 1rem;
}

.feature-previeW-container {
  border: 1px solid #fff;
  padding: 0rem 1.5rem;
  color: #fff;
  height: 60px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.feature-previeW-container .cross-line {
  position: absolute;
  height: 2px;
  background: #fff;
  width: 120%;
  transform: rotate(147deg);
  transition: width 2s;
  -webkit-transition: width 2s;
  -moz-transition: width 2s;
  display: block;
}

.feature-previeW-container svg {
  font-size: 2rem;
}

.feature-previeW-container img {
  width: 36px;
}


.profileMain .secondryHeading {
  font-weight: unset !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileMain_portal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 10px;
}

@media (max-width: 824px) {
  .main .dashboardMain {
    z-index: 0;
    margin: 0;
  }

  .profile_main .navLinks .tab-content {
    padding: 10px;
  }

  .portal-profile-page-table .day {
    position: absolute;
    top: 25px;
    left: 1rem;
  }

  .navbarMain .dropdown {
    max-width: 100% !important;
    width: auto !important;
  }

  .form-switch {
    padding-left: 0 !important;
    width: 38px;
  }
}

@media (max-width: 770px) {
  .profile_main .customTable th {
    font-size: 1rem !important;
  }

  .breadCrumb {
    margin-top: 50px;
  }

  .w-100 {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .profile_main .navLinks .tab-content .formInput input {
    width: 95% !important;
  }

  .portal-profile-page-table .day {
    position: absolute;
    top: 0;
    left: 15px;
  }

  .leagueMatches .btn-fixture {
    width: 36%;
  }

  .profile_main .customTable th {
    font-size: 12px !important;
  }
}