.pointer {
    cursor: pointer;
}

.row {
    --bs-gutter-x: 1.5rem !important;
}

.home-container {
    scroll-behavior: smooth;
}

.hero_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.hero_content p {
    margin: 5px !important;
}

.hero_content h5 {
    margin: 5px !important;
}

.card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    height: 12rem;
    color: var(--bs-card-color);
}

.hero h1 {
    font-size: 3.3rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #3f3d56;
    margin-top: 1rem;
}

.hero h5 {
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 600;
    color: var(--primary-color);
    margin: 1rem auto;
}

.hero p {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 400;
    color: Gray;
    margin: 1rem auto;
}

.hero button {
    width: 88%;
    background-color: var(--primary-color);
    padding: 1rem 1rem;
    border: none;
}

.hero button:hover {
    width: 88%;
    background-color: var(--primary-color) !important;
    padding: 1rem 1rem;
}

.hero-section {
    max-width: 1400px;
    margin: 0 auto;
}

.hero .hero-img {
    text-align: center;
}

.hero .hero-img img {
    height: 400px;
    width: 100%;
    max-width: 400px;
}

.hero .hero-img .home-first-slide {
    height: 400px !important;
}

.hero .hero-img a {
    text-decoration: none;
    color: #3f3d56;
}

.hero .hero-img span {
    font-size: 1.3rem;
}


/* STEPS */

.steps {
    background-color: var(--primary-color);
    height: 100%;
    padding-bottom: 5rem;
    margin-top: 3rem;
}

.steps h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
}

.steps .card-title {
    font-weight: 700;
    text-transform: uppercase;
}

/* OFFERS */

.offers {
    margin-top: 7rem;
}

.offers h1 {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--primary-color);
    margin-top: 1rem;
}

.offers h5 {
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 500;
    color: #898989;
    margin: 1rem auto;
}

.offers p {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 400;
    color: rgb(0, 0, 0);
    margin: 1rem auto;
}

.offers button {
    width: 88%;
    background-color: var(--primary-color) !important;
    padding: 1rem 1rem;
    border: none;
}

.offers button:hover {
    width: 88%;
    background-color: var(--primary-color);
    padding: 1rem 1rem;
}

.offers .hero-img {
    text-align: center;
}

.offers .hero-img img {
    height: 510px;
}

.offers .hero-img a {
    text-decoration: none;
    color: #3f3d56;
}

/* footer */

.footer {
    background-color: var(--primary-color);
    height: 100%;
    margin-top: 4rem;
    display: flex;
    align-items: center;
}

.storeicons {
    display: flex;
    align-items: center;
}

.footer .footer-logo-1 {
    margin-top: -3rem;
    max-width: 300px;
    width: 100%;
    text-align: center;
    height: 90px;
}

.footer .footer-logo-2-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.footer .footer-logo-2-wrapper .f-logo2 {
    width: 150px;
    height: 100px;
}

.footer .footer-logo-2-wrapper .f-logo3 {
    width: 200px;
    height: 90px;
    margin: 0.5rem 0 0 1rem;
}

.footer .footerLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.footer .footerLinks button {
    font-size: 1rem;
}

.footer .footerLinks a {
    max-width: 100px;
    color: #fff;
    margin-left: 13px;
    font-size: 1.3rem;
    text-decoration: none;
}

.footerLinks .btn-account {
    margin-left: 16px;
}

.footer .contact-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.footer .contact-info a {
    text-decoration: none;
    color: #000;
    width: 120px;
    text-align: center;
    padding: 0.4rem 0;
    background-color: #fff;
    border-radius: 0.3rem;
    font-weight: 700;
    margin-left: 2.2rem;
}

.footer .contact-info .icon {
    color: #fff;
    margin-left: 2rem;
    font-size: 1.5rem;
    margin-top: 0.7rem;
    text-decoration: none;
}

.footer .contact-info button {
    color: rgb(28, 28, 28);
    margin-left: 2rem;
    font-size: 0.8rem;
    text-decoration: none;
    margin-top: 2rem;
    border: none;
    padding: 0.4rem 2.3rem;
}

.footer .storeicons-wrapper,
.storeicons .storeicons-wrapper {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 40px;
}

.footer .storeicons-wrapper img,
.storeicons .storeicons-wrapper img {
    width: 180px;
    height: 60px;
    border-radius: 5px;
    margin-top: 20px;
}

.list-item {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    font-size: 1rem;
}

.faq-container .sec-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.faq-container .row {
    display: flex;
    justify-content: center;
}

.faq-container input {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}

.faq-container ul {
    list-style: none;
    text-decoration: none;
}

.faq-container ul li {
    padding: 1rem 0;
}

.faq-container ul p {
    color: grey;
}

.faq-container .list-group-item1:not(:last-child) {
    border-bottom: 1px solid #d6d6d6 !important;
}

.faq-container .list-group-item1 svg {
    font-size: 1.7rem;
}

.faq-container .list-group-item1 span {
    font-weight: bold;
    font-size: 1.1rem;
}

.venue-wrapper .single-item {
    position: relative;
}

.venue-wrapper .single-item img {
    max-height: 270px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    position: relative;
}

.venue-wrapper .single-item .btn-wrapper,
.venue-wrapper .single-item:hover .btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 10px;
}

.venue-wrapper .single-item button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}

.single-venue-wrapper {
    margin-top: 50px;
}

.single-venue-wrapper svg,
.venue-list svg {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
}

.details-wrapper {
    border: 1px solid #dadada;
    border-radius: 5px;
    max-height: 320px;
}

.details-wrapper h3 {
    font-weight: bold;
    padding: 1rem 0;
}

.details-wrapper hr {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 0;
}

.details-wrapper .league-wrapper {
    display: flex;
    justify-content: space-between;
}

.details-wrapper .league-wrapper p {
    font-size: 1.1rem;
    font-weight: 500;
    color: #898989;
}

.details-wrapper .team-wrapper {
    display: flex;
    padding: 0.5rem 0;
}

.details-wrapper .team-wrapper img {
    width: 40px;
    margin-right: 1rem;
}

.details-wrapper .team-wrapper p {
    font-size: 1.1rem;
    font-weight: 600;
}

.img-wrapper {
    border: 1px solid #e2e2e2;
    border-radius: 1rem;
    margin: 0 1rem;
    padding: 1rem;
}

.facilities-wrapper .Custom_container {
    margin: 0 !important;
    padding: 2rem !important;
}

.facilities-wrapper .feature-previeW-container {
    height: 4rem !important;
}

.working-hours-wrapper h6 {
    font-weight: 800;
    text-transform: capitalize;
}

.working-hours-wrapper .time-wrapper {
    display: flex;
    justify-content: space-between;
}

.home-container hr {
    height: 3px;
    background-color: #00985f;
    opacity: 1;
    border: none;
}

.venues-wrapper {
    margin: 20px auto 100px;
}

.venues-wrapper .venues-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.venues-wrapper .venues-header h1 {
    font-weight: bolder;
}

.venu-home-breaker {
    height: 60px !important;
}

.venue-list-item {
    margin-bottom: 25px;
}

.venue-list-item img {
    width: 150px;
    height: 150px;
    border-radius: 5px;
}

.venue-list-item .venue-list-item-inner-container {
    display: flex;
    justify-content: space-between;
}

.venue-list-item div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.story-container {
    background-image: url("/assets/grass.jpg");
    height: 300px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.story-container p {
    max-width: 350px;
    font-size: 18px;
    text-align: center;
}

.empty-container {
    min-height: 100px;
    background-size: cover;
    background: inherit !important;
}

.fixture-client-container {
    text-align: center;
    width: 100% !important;
}

.fixture-client-container h1 {
    font-weight: bolder;
}

.fixture-calender-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 50px;
}

.fixture-calender-wrapper svg {
    font-size: 40px;
    margin-top: 50px;
}

.awssld__content {
    background-color: unset !important;
}

.awssld__container {
    padding-bottom: 40rem !important;
}

.restaurentCardHome {
    height: 400px !important;
}

.restaurentCardHome img {
    height: 300px !important;
}

.singleVenueItem {
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #bfc0bf;
    margin-bottom: 10px;
    margin-right: 10px !important;
}

.singleVenueItem img {
    width: 100px !important;
    height: 80px !important;
}

.third-slide-img {
    width: 100%;
    height: 600px;
}

@media screen and (max-width: 700px) {
    .hero_content {
        margin: 20px 0;
    }

    .hero_content h1 {
        font-size: 2.3rem;
    }

    .hero_content .second-slide-header {
        font-size: 3rem !important;
    }

    .hero_content .hero-reg-btn {
        margin-bottom: 80px !important;
    }

    .third-slide-img {
        height: 600px;
    }
}

/* Landing page */


.fs-40 {
    font-size: 40px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.main-app-wrapper {
    max-width: 1500px;
    margin: 0 auto;
    font-family: "Barlow Condensed", sans-serif;
}

.btn-primary {
    background-color: var(--primaryColor) !important;
    border: 1px solid var(--primaryColor);
}

.btn-primary:hover {
    background-color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
}

.container-fluid {
    padding-inline: 4rem !important;
}

.header {
    position: fixed;
    background: var(--primaryColor);
    color: #fff;
    height: 90px;
    width: 100%;
    z-index: 3;
}

.header .nav-link {
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

.header a.dropdown-item {
    color: #777;
}

.header .navbar-nav {
    color: #fff;
    margin-right: 7rem;
}

.header .linkBtn {
    padding: 0.5rem 2rem !important;
}

.header nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100%;
    max-height: 90px;
}

/* hero */

.landingHero {
    background-image: linear-gradient(to bottom, #05050596, #0505053b),
        url("/assets/LandingImages/hero.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 180px 0;
}

.landingHero .heroContent {
    color: #fff;
    margin-top: 1rem;
}

.landingHero .sub-heading {
    font-size: 20px;
}

.landingHero .heroContent h1 {
    color: #fff;
    font-size: 40px;
    line-height: 4rem;
}

.heroContent p {
    color: #fff;
}

.landingHero .btnCustom,
.landingHero .btnCustom:hover {
    padding: 1rem 5rem;
    margin-top: 1rem;
    background-color: var(--primaryColor) !important;
    color: white;
    font-size: 20px;
}

.landingHero .heroContent .stores {
    margin-top: 110px;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    gap: 50px;
}

.landingHero .heroContent .stores .storeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 1rem;
    cursor: pointer;
}

.landingHero .heroContent .stores .storeBtn img.playstore-icon {
    width: 300px !important;
    height: 122px !important;
}

.landingHero .heroContent .stores .storeBtn img {
    width: 250px;
    height: 83px;
    transform: translateY(-0.5rem);
}

.landingHero .heroContent .stores .btnContent p {
    font-size: 1.4rem;
}

.landingHero .heroContent .stores .btnContent span {
    font-size: 0.8rem;
}

/* venue */

.venue {
    margin-top: 7rem;
}

.venue .venueContent {
    margin-top: 8rem;
}

.venue .venueContent h1 {
    font-size: 2rem;
    font-weight: 500;
    color: #4d4d4d;
}

.venue .venueContent p {
    font-size: 20px;
    font-weight: 400;
    color: #4d4d4d;
}

.venue .venueContent .btnCustom,
.venue .venueContent .btnCustom:hover {
    padding: 1rem 5rem;
    margin-top: 1rem;
    background-color: var(--primaryColor) !important;
    color: #fff;
    font-size: 20px;
}

/* about */

.about {
    height: 100%;
    padding-block: 4rem;
    color: #fff;
    background-image: url("/assets/LandingImages/aboutBG.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 5rem !important;
}

.about .transparentLogo {
    position: relative;
}

.about .transparentLogo .transparentLogoImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%, -50%);
}

.about h5 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
}

.about h1 {
    font-size: 3rem;
    font-weight: 500;
}

/* about2 */

.about2 {
    background-image: url("/assets/LandingImages/about2BG.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    padding-block: 5rem;
    color: #fff;
}

.about2 h5 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
}

.about2 h1 {
    font-size: 3rem;
    font-weight: 500;
}

.about2 .about2Card {
    margin-top: 2rem;
}

.about2 .about2Card h3 {
    color: var(--primaryColor);
    font-size: 2.5rem;
    font-weight: 700;
}

/* features */

.features {
    margin-top: 3rem;
}

.features h1 {
    text-align: center;
    font-weight: 500;
    color: #333333;
    position: relative;
}


.features .featureContent {
    margin-top: 7rem;
}

.features .featureContent h2 {
    margin-bottom: 2rem;
    font-weight: 700;
    color: #333333;
    position: relative;
    display: inline-block;
}


.features .featureContent p {
    font-size: 1.2em;
    color: #4d4d4d;
}

.features .featureContent button.btnCustom,
.features .featureContent button.btnCustom:hover {
    padding: 1rem 5rem;
    margin-top: 1rem;
    background-color: var(--primaryColor) !important;
    color: #fff;
}

/* offer */

.offer {
    background-color: var(--primaryColor);
    height: 100%;
    padding-top: 2rem;
    margin-top: 4rem;
    color: #fff;
    padding-bottom: 4rem;
}

.offer h1 {
    text-align: center;
    font-weight: 500;
    color: #fff;
    position: relative;
}


.offer .btnCustom,
.offer .btnCustom:hover {
    padding: 17px 6rem;
    background-color: #444444;
    width: 100%;
}

.offer .offerContent {
    margin-top: 50px;
}

.offer .offerContent h5 {
    margin-bottom: 30px;
    font-size: 2.7rem;
}

.offer .offerContent p {
    margin-bottom: 30px;
    font-size: 1.1rem;
    line-height: 2rem;
}

/* contact */

.contact {
    margin-top: 2rem;
}

.contact .inputs {
    margin-block: 2rem;
}

.contact .inputs .input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2rem;
}

.contact .inputs .input span {
    font-size: 20px;
}

.contact .inputs .input input {
    padding: 1rem;
    outline: none;
    background-color: #f0f2f6;
    border: none;
    font-size: 20px;
}

.contact .inputs .input textarea {
    padding: 1rem;
    outline: none;
    background-color: #f0f2f6;
    border: none;
    font-size: 20px;
}

.contact .inputs .input button {
    background-color: var(--primaryColor);
    color: #fff;
    padding: 0.8rem;
}

/* footer */

.footer {
    background-color: #444444;
    padding-block: 2rem;
}

.footer .stores {
    margin-top: 70px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
}

.footer .stores .storeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 5rem 0.2rem 2rem;
    background-color: #333333;
    color: #fff;
    gap: 20px;
    padding-top: 1rem;
    border-radius: 10px;
    cursor: pointer;
}

.footer .stores .storeBtn img {
    transform: translateY(-0.5rem);
}

.footer .stores .btnContent p {
    font-size: 1.4rem;
}

.footer .stores .btnContent span {
    font-size: 0.8rem;
}

.footer .socialIcons {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.footer .socialIcons i {
    color: #fff;
    font-size: 2.1rem;
}

.footer button {
    background-color: var(--primaryColor);
    padding: 1rem 5rem;
    color: #fff;
}

.navbar-toggler:not(:disabled):not(.disabled) {
    height: 40px;
    width: 43px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    position: relative;
}

.landing-container .navbar-toggler-icon {
    background-image: none;
}

.navbar-toggler .navbar-toggler-icon {
    width: 1em;
    height: 1em;
}

.toggleImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.venueButton {
    background-color: #343434;
    border-radius: 4px;
    margin-left: 10px;
}

.footer-left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}

.footer-left .footer-logo {
    width: 150px;
    margin-bottom: 20px;
}

.footer-left p {
    font-size: 18px;
}

.footer-left h6 {
    margin-bottom: 20px;
}

.footer-left .stores-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

.footer-left .stores-btns .store_btn img {
    width: 30px !important;
}

.footer-left .stores-btns .store_btn p {
    margin: 0;
}

.footer-left .stores-btns .store_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 10px;
}

.footer-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.footer-right img {
    width: 300px;
}

.social-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    margin-top: 20px;
}

.social-icons .icon {
    font-size: 30px;
}

/* RESPONSIVNESS */

/* 725 */

@media (max-width: 725px) {
    .landingHero .heroContent .stores {
        margin-top: 110px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 50px;
    }

    .landingHero .heroImg {
        margin-top: 4rem;
    }

    .offer .btnCustom,
    .offer .btnCustom:hover {
        padding: 17px;
        background-color: #444444;
        width: 100%;
    }

    .offer {
        padding-bottom: 4rem;
    }

    .features .featureContent button.btnCustom,
    .features .featureContent button.btnCustom:hover {
        padding: 1rem 7rem;
        margin-block: 4rem;
    }

    .header nav {
        width: 70%;
    }

    .venueButton {
        margin-left: 0;
        margin-top: 20px
    }
}