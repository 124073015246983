.navbarMenu {
  color: #fff;
  margin-right: 3rem;
  cursor: pointer;
}

.dashboardMain,
.container {
  transition: 0.3s all ease-in-out;
}

nav {
  position: fixed !important;
}

.navbarMain {
  background: #00985f;
  color: #ffffff !important;
  padding: 0.5rem 1.3rem !important;
  font-weight: 500;
  width: 100%;
  z-index: 1;
}

.nav-position {
  position: initial !important;
}

.main .dashboardMain {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  margin-top: 1rem;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.adminImageWrapper {
  width: 50px;
  height: 50px;
}

.adminImage {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  margin-top: 8px;
}

.settingIcon {
  width: 20px !important;
  height: 20px;
  border-radius: 50%;
  margin-top: 5px;
}

.settingItem {
  font-size: 1.7rem;
  /* margin-bottom: 1rem; */
  transform: translateY(-3px);
}

.menu {
  margin-right: 4rem;
}

.main {
  display: flex;
}

/* SIDEBAR */
.main .sidebar {
  position: fixed;
  background-color: #444;
  width: 300px !important;
  flex: -1 4;
  height: 100%;
  animation: 0.5s sidebar_move forwards;
}

.main .sidebar::after {
  content: "";
  position: absolute;
  background-color: #444;
  width: 100%;
  height: 131px;
}

.venue-sidebar .dropdown-toggle::after {
  display: none !important;
}

@keyframes sidebar_move {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

.main .sidebar .sidebar_links {
  margin-top: 5rem;
}

.main .sidebar .sidebar_link {
  font-size: 0.9rem;
  width: 100%;
  padding: 0.7rem 2rem;
  cursor: pointer;
  color: #fff;
}

.main .sidebar .sidebar_link img {
  width: 20px;
  height: 20px;
}

.main .sidebar .sidebar_link span {
  margin-right: 1rem;
}

.main .sidebar .sidebar_link.active {
  background-color: #fff;
  font-size: 1rem;
  width: 100%;
  padding: 0.7rem 2rem;
  cursor: pointer;
  color: #444;
}

/* 
.main .dashboardMain {
  height: 101.5vh;
} */

.offers-wrapper {
  margin-top: 100px;
}

.main .dashboardMain .breadCrumb h4 {
  margin-right: 2rem;
  margin-top: 5rem;
}

.main .dashboardMain .breadCrumb .breadCrumbBadge {
  padding: 0.5rem 2rem;
  background-color: #444 !important;
  font-size: 0.8rem;
  cursor: pointer;
}

.main .mainCard {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 7rem !important;
  padding-bottom: 6rem;
  margin-top: 1rem;
  background-color: #f3f3f3;
  border: none;
  position: relative;
  height: 4vh;
  cursor: pointer;
}

.main .mainCard .notificationBadge {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-color: var(--primary-color);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
}

.main .mainCard .notificationBadge p {
  font-size: 1.1rem;
}

.main .mainCard img {
  width: 40px;
}

.main .mainCard .cardTitle {
  text-align: center;
  color: #444444;
}

.main .mainCard .cardText {
  text-align: center;
}

.left-margin {
  margin-left: 300px;
}

.nav-venueName {
  margin-top: 0.8rem;
  margin-left: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.selectLanguage {
  background-color: #343434 !important;
  color: #fff !important;
  border: none !important;
}

/* RESPONSIVENESS */
@media (max-width: 1200px) {
  .navbarMain {
    z-index: 3 !important;
  }

  .nav-position {
    position: initial;
  }
}

@media (max-width: 824px) {
  .main .sidebar {
    width: 37% !important;
    position: fixed;
  }

  .main .sidebar {
    z-index: 2;
  }

  .navbarMain {
    z-index: 3 !important;

  }

  .main .dashboardMain {
    z-index: 0;
  }

  .left-margin {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .main .sidebar {
    width: 80% !important;
  }
}

@media (max-width: 453px) {

  .dashboardMain input,
  .dashboardMain select {
    color: #898989;
    text-transform: capitalize;
    background-color: #f0f2f6;
    padding: 1rem 1rem;
    width: 100% !important;
    border: none;
  }
}